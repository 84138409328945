import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import BCover from '../static/bilaal-cover.png';
import NCover from '../static/video/nhs-poster.png';
import WCover from '../static/wonz-poster.jpg';
import Bilaal from '../static/video/EWY_Bilaal.mp4';
import Nhs from '../static/video/NHS-Join-the-LCH-team.mp4';
import Wonz from '../static/wonz.mp4';
import PlayButton from '../static/play--button.svg';

const VideoPage = (props) => {
    const { data, errors } = props;

    const bilaalVideo = useRef();
    const playButtonB = useRef();

    const [isPlayingB, setIsPlayingB] = useState(false);

    const handlePlayB = e => {
        if(!isPlayingB) {
            setIsPlayingB(true);
            bilaalVideo.current.play();
        } else {
            setIsPlayingB(false);
            bilaalVideo.current.pause();
        }
    }

    const NhsVideo = useRef();
    const playButtonN = useRef();

    const [isPlayingN, setIsPlayingN] = useState(false);

    const handlePlayN = e => {
        if(!isPlayingN) {
            setIsPlayingN(true);
            NhsVideo.current.play();
        } else {
            setIsPlayingN(false);
            NhsVideo.current.pause();
        }
    }

    const wonzVideo = useRef();
    const playButtonW = useRef();

    const [isPlayingW, setIsPlayingW] = useState(false);

    const handlePlayW = e => {
        if(!isPlayingW) {
            setIsPlayingW(true);
            wonzVideo.current.play();
        } else {
            setIsPlayingW(false);
            wonzVideo.current.pause();
        }
    }

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        <Container>
            <div className='site-wide'>
                <div className='aviva--page-banner-image showreel' id="bilaal">
                    <div className='video--wrapper'>
                        {isPlayingB ? null :
                            <div className='video--overlay' onClick={handlePlayB}>
                                <img src={PlayButton} ref={playButtonB} />
                            </div>
                        }
                        <video
                            ref={bilaalVideo}
                            className="hero__media"
                            controls="false"
                            poster={BCover}
                        >
                            <source src={Bilaal} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="kirsty">
                    <div className='video--wrapper'>
                        {isPlayingN ? null :
                            <div className='video--overlay' onClick={handlePlayN}>
                                <img src={PlayButton} ref={playButtonN} />
                            </div>
                        }
                        <video
                            ref={NhsVideo}
                            className="hero__media"
                            controls="false"
                            poster={NCover}
                        >
                            <source src={Nhs} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className='aviva--page-banner-image showreel' id="shibon">
                    <div className='video--wrapper'>
                        {isPlayingW ? null :
                            <div className='video--overlay' onClick={handlePlayW}>
                                <img src={PlayButton} ref={playButtonW} />
                            </div>
                        }
                        <video
                            ref={wonzVideo}
                            className="hero__media"
                            controls="false"
                            poster={WCover}
                        >
                            <source src={Wonz} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </Container>
        </Layout>
    </>
  )
};

VideoPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default VideoPage;
